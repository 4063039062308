<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="groupHeaders"
            :items="groups"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title> 分组列表/Group List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog persistent v-model="addGroupDialog" max-width="400px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Add </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">新建分组/New Group</span>
                    </v-card-title>

                    <v-card-text>
                      <v-text-field v-model="newGroupName" label="名称/Name"></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="groupAddClose"> Cancel </v-btn>
                      <v-btn color="blue darken-1" text @click="groupAddConfirm"> Save </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>

              <v-dialog persistent v-model="editGroupDialog" max-width="90%">
                <v-card>
                  <v-card-title>
                    <span class="headline"
                      >编辑分组成员/Edit Group Members - {{ editGroup.name }}</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <v-autocomplete
                      label="学生/Students"
                      v-model="editGroup.students"
                      :items="studentItems"
                      item-text="desc"
                      item-value="id"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="removeStudentFromGroup(item)"
                        >
                          {{ item.desc }}
                        </v-chip>
                      </template>
                    </v-autocomplete>

                    <v-autocomplete
                      v-model="editGroup.professors"
                      :items="professorItems"
                      item-text="name"
                      item-value="id"
                      label="评委/Professor"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="removeProfessorFromGroup(item)"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center">
                    <v-btn color="blue darken-1" text @click="editGroupMemberClose"> Cancel </v-btn>
                    <v-btn color="blue darken-1" text @click="editGroupMemberSave"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog persistent v-model="deleteGroupDialog" max-width="640px">
                <v-card>
                  <v-card-title class="headline">删除分组 - {{ editGroup.name }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="groupDeleteClose">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="groupDeleteConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <v-spacer></v-spacer>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="handleEditGroupMembers(item)">
                    mdi-pencil
                  </v-icon>
                </template>
                <span>编辑成员/Edit Group Members</span>
              </v-tooltip>
              <v-icon @click="handleDeleteGroup(item)"> mdi-delete </v-icon>
            </template>

            <template v-slot:[`item.students`]="{ item }">
              <v-chip class="ma-1" small v-for="student in item.students" :key="student.name">
                {{ student.desc }}</v-chip
              >
            </template>

            <template v-slot:[`item.professors`]="{ item }">
              <v-chip class="ma-1" v-for="professor in item.professors" :key="professor.name">{{
                professor.name
              }}</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import requests from "@/http";

export default {
  data: () => ({
    search: "",
    addGroupDialog: false,
    newGroupName: "",
    editGroupDialog: false,
    deleteGroupDialog: false,
    groupHeaders: [
      { text: "名称", value: "name" },
      { text: "评委", value: "professors" },
      { text: "学生", value: "students" },
      {
        text: "操作/Actions",
        sortable: false,
        value: "actions",
      },
    ],
    studentItems: [],
    professorItems: [],
    editGroup: {
      id: 0,
      name: "",
      students: [],
      professors: [],
    },
    emptyGroup: {
      id: 0,
      name: "",
      students: [],
      professors: [],
    },
    groups: [],
    userToAdd: [],
    waitingStudents: [],
    waitingProfessors: [],
  }),
  created() {
    this.getAllGroup();
    this.getWaitingUsers();
  },
  methods: {
    getAllGroup() {
      requests.get(`/api/admin/group/full`).then((res) => {
        this.groups = res.data.groups;
      });
    },
    getWaitingUsers() {
      requests.get(`/api/admin/group/waiting`).then((res) => {
        this.waitingStudents = res.data.students;
        this.waitingProfessors = res.data.professors;
      });
    },
    removeStudentFromGroup(item) {
      this.editGroup.students = this.editGroup.students.filter((student) => student.id !== item.id);
    },
    removeProfessorFromGroup(item) {
      this.editGroup.professors = this.editGroup.professors.filter(
        (professor) => professor.id !== item.id
      );
    },

    groupAddClose() {
      this.$nextTick(() => {
        this.newGroupName = "";
      });
      this.addGroupDialog = false;
    },
    groupAddConfirm() {
      requests
        .post(`/api/admin/group/add`, { name: this.newGroupName })
        .then(() => {
          this.getAllGroup();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
      this.groupAddClose();
    },

    editGroupMemberClose() {
      this.$nextTick(() => {
        this.editGroup = Object.assign({}, this.emptyGroup);
        this.studentItems = [];
        this.professorItems = [];
      });
      this.editGroupDialog = false;
    },
    editGroupMemberSave() {
      requests
        .post(`/api/admin/group`, this.editGroup)
        .then(() => {
          this.getAllGroup();
          this.getWaitingUsers();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
      this.editGroupMemberClose();
    },
    handleEditGroupMembers(item) {
      this.editGroupDialog = true;
      this.editGroup = JSON.parse(JSON.stringify(item));
      item.professors.forEach((p) => {
        this.professorItems.push(p);
      });
      item.students.forEach((s) => {
        this.studentItems.push(s);
      });
      this.waitingProfessors.forEach((p) => {
        this.professorItems.push(p);
      });
      this.waitingStudents.forEach((s) => {
        this.studentItems.push(s);
      });
    },

    handleDeleteGroup(item) {
      this.deleteGroupDialog = true;
      this.editGroup = JSON.parse(JSON.stringify(item));
    },
    groupDeleteClose() {
      this.$nextTick(() => {
        this.editGroup = Object.assign({}, this.emptyGroup);
      });
      this.deleteGroupDialog = false;
    },
    groupDeleteConfirm() {
      requests
        .post(`/api/admin/group/${this.editGroup.id}/delete`, {})
        .then(() => {
          this.getAllGroup();
          this.getWaitingUsers();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
      this.groupDeleteClose();
    },
  },
};
</script>
